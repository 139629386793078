import React from 'react';

function Navbar() {
    return (
      <div>
        {/* <nav id="sidebarMenu" className="col-md-3 col-lg-2 d-md-block bg-light sidebar collapse">
            <div className="position-sticky pt-3 sidebar-sticky">
              <div className="d-flex flex-column flex-shrink-0 p-3 bg-light">
                <a href="/" className="d-flex align-items-center mb-3 mb-md-0 me-md-auto link-dark text-decoration-none">
                  <svg className="bi pe-none me-2" width="40" height="32">
                  </svg>
                  <span className="fs-4">الموثق</span>
                </a>
                <hr />
                <ul className="nav nav-pills flex-column mb-auto">
                  <li className="nav-item">
                    <a href="#" className="nav-link active" aria-current="page">
                      <svg className="bi pe-none me-2" width="16" height="16">
                      </svg>
                      البحث عن موثق
                    </a>
                  </li>
                  <li>
                    <a href="#" className="nav-link link-dark">
                      <svg className="bi pe-none me-2" width="16" height="16">
                      </svg>
                      وثائق العقود
                    </a>
                  </li>
                  <li>
                    <a href="#" className="nav-link link-dark">
                      <svg className="bi pe-none me-2" width="16" height="16">
                      </svg>
                      حساب تكلفة عقد
                    </a>
                  </li>
                  <li>
                    <a href="#" className="nav-link link-dark">
                      <svg className="bi pe-none me-2" width="16" height="16">
                        <use href="#grid"></use>
                      </svg>
                      مراسلة الهيئات
                    </a>
                  </li>
                  <li>
                    <a href="#" className="nav-link link-dark">
                      <svg className="bi pe-none me-2" width="16" height="16"><use href="#people-circle"></use></svg>
                      طلب إستشارة
                    </a>
                  </li>
                  <li>
                    <a href="#" className="nav-link link-dark">
                      <svg className="bi pe-none me-2" width="16" height="16"><use href="#people-circle"></use></svg>
                      الأنشطة الإقتصادية
                    </a>
                  </li>
                  <li>
                    <a href="#" className="nav-link link-dark">
                      <svg className="bi pe-none me-2" width="16" height="16"><use href="#people-circle"></use></svg>
                      حساب الفرائض
                    </a>
                  </li>
                  <li>
                    <a href="#" className="nav-link link-dark">
                      <svg className="bi pe-none me-2" width="16" height="16"><use href="#people-circle"></use></svg>
                      قانون التسجيل
                    </a>
                  </li>
                </ul>
                <hr />
                <div className="dropdown">
                  <a href="#" className="d-flex align-items-center link-dark text-decoration-none dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                    <img src="https://github.com/mdo.png" alt="" width="32" height="32" className="rounded-circle me-2" />
                    <strong>mdo</strong>
                  </a>
                  <ul className="dropdown-menu text-small shadow">
                    <li><a className="dropdown-item" href="#">New project...</a></li>
                    <li><a className="dropdown-item" href="#">Settings</a></li>
                    <li><a className="dropdown-item" href="#">Profile</a></li>
                    <li><hr className="dropdown-divider" /></li>
                    <li><a className="dropdown-item" href="#">Sign out</a></li>
                  </ul>
                </div>
              </div>
            </div>
          </nav> */}
          {/* <nav className="navbar navbar-expand-lg bg-light">
        <div className="container-fluid">
          <a className="navbar-brand" href="#">Navbar</a>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <a className="nav-link active" aria-current="page" href="#">Home</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#">Link</a>
              </li>
              <li className="nav-item dropdown">
                <a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  Dropdown
                </a>
                <ul className="dropdown-menu">
                  <li><a className="dropdown-item" href="#">Action</a></li>
                  <li><a className="dropdown-item" href="#">Another action</a></li>
                  <li><hr className="dropdown-divider" /></li>
                  <li><a className="dropdown-item" href="#">Something else here</a></li>
                </ul>
              </li>
              <li className="nav-item">
                <a className="nav-link disabled">Disabled</a>
              </li>
            </ul>
            <form className="d-flex" role="search">
              <input className="form-control me-2" type="search" placeholder="Search" aria-label="Search" />
              <button className="btn btn-outline-success" type="submit">Search</button>
            </form>
          </div>
        </div>
      </nav> */}
      </div>
    )
}

export default Navbar;