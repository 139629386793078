import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom';
import axios from 'axios'


function DocumentsDetails() {
    const [data, getdata] = useState(0);
    const [files, getfiles] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const params = useParams();
    const getAllNotes = (id) => {
        axios.get('https://api.localnotaire.com/type_acts.php?id=' + id)
            .then(response => {
                console.log(response);
                getdata(response.data.type_acts[0]);
                getfiles(response.data.type_acts[0].file);
            })
            .catch(error => {
                console.error(error)
            })
    }

    useEffect(() => {
        getAllNotes(params.id)
    }, [params.id])



    const arr = files.toString().replace('[', '')
    const arr2 = arr.toString().replace(']', '')
    const arr3 = arr2.toString().replaceAll('\"', '')
    const arr4 = arr3.split(',')
    //console.log(arr4)
    return (
        <div className='sticky-top'>
            <h4>الوثائق اللازمة للعقد</h4>
            <div className="p-2 mb-4 bg-light rounded-3 documentsDetails">
                <div className="container-fluid py-2">
                    <h6 className="display-6 fw-bold">{data.name}</h6>
                    <p className="col-12 fs-6">{data.name_fr}</p>
                    {/* <p className="col-12 fs-6">{data.file}</p> */}
                    <ul className="col-12 fs-6">
                        { arr4.length ? arr4.map(function(item, i){
                            //console.log('test');
                            return <li key={i}>{item}</li>
                            }) : null }
                    </ul>
                    <p className="col-12 fs-6">{data.information}</p>
                    {/* <button className="btn btn-primary btn-lg" type="button">{params.id}</button> */}
                </div>
            </div>
        </div>
    )
}

export default DocumentsDetails
