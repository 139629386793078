import React, { Component } from 'react'
import { BrowserRouter as Router, Link, Route } from 'react-router-dom'

import axios from 'axios'


class Documents extends Component {
    constructor(props) {
        super(props)
        this.state = {
            posts: [],
            error: [],
        }
    }

    componentDidMount() {
        axios.get('https://api.localnotaire.com/type_acts.php')
            .then(response => {
                // console.log(response)
                this.setState({ posts: response.data.type_acts })
            })
            .catch(error => {
                // console.log(error)
                this.setState({ error: 'حدث خطأ أثناء جلب المعلومات.' })

            })
    }


    render() {
        const { posts } = this.state
        const { error } = this.state
        // console.log(posts)


        return (
            <div>
                <h4>أنواع العقود</h4>
                <div className='list-group'>
                    {
                        posts.length ?
                            posts.map(post => <Link to={'/documents/' + post.id} key={post.id} className="list-group-item list-group-item-action">{post.name}</Link>) :
                            null
                    }
                    {error.length ? <div className="alert alert-danger" role="alert">{error}</div> : null}
                </div>
                
            </div>
        )
    }
}

export default Documents;