import React from 'react';

function Footer() {
    return (
      <div>
        {/* <footer className="d-flex flex-wrap justify-content-between align-items-center py-3 my-4 border-top">
        </footer> */}
      </div>
      )
}

export default Footer;