import React, { Component } from 'react';
import { BrowserRouter as Router, Link, Routes, Route } from 'react-router-dom'

import Navbar from './components/layouts/navbar';
import Footer from './components/layouts/footer';
import Header from './components/layouts/header';
import LoadingSpinner from './components/layouts/LoadingSpinner';
import Documents from './components/documentsContract';
import DocumentsDetails from './components/doumentsDetails';
import Table from './components/defaultTable';

function App() {
  return (
    <div className="App">
      <Header />
      <div className="b-example-divider"></div>
      <div className="container-fluid">
        <Navbar />
        <main className="col-md-12 ms-sm-auto col-lg-12 px-md-4">
          <Router>
          <nav className="navbar fixed-top mt-5">
                  <div className="container-fluid">
                    <button className="navbar-toggler bg-light d-block d-lg-none" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar">
                      <span className="navbar-toggler-icon"></span> أنواع العقود
                    </button>
                    <div className="offcanvas offcanvas-end" tabIndex="-1" id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel">
                      <div className="offcanvas-header">
                        <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                      </div>
                      <div className="offcanvas-body">
                        <Documents />
                      </div>
                    </div>
                  </div>
                </nav>
            <section className="py-5 container">
              <div className='row'>
                <div className='col-3 d-none d-lg-block'>
                <Documents />
                </div>
                
                <div className='col-lg-9 col-sm-12'>
                  <Routes>
                    <Route path="documents/:id" element={<DocumentsDetails />}></Route>
                  </Routes>
                </div>
              </div >
            </section>
          </Router>
        </main>
      </div>
      <div className="b-example-divider"></div>
      <Footer />
    </div >
  );
}

export default App;
